<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 605px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">イベント<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <a @click="navigate('/admin/event')" style="cursor: pointer"
                >ダッシュボード</a
              >
            </li>
            <li>
              <a @click="navigate('/admin/event')" style="cursor: pointer"
                >イベント</a
              >
            </li>
            <li><span>編集</span></li>
          </ul>
        </nav>
      </div>

      <div class="action_wrap m_b5" v-if="commonValidationError">
        <p class="s_err">
          入力内容に不備があります。<span
            ><i class="fas fa-times msg_remove"></i
          ></span>
        </p>
      </div>

      <div
        class="modal"
        id="discardModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal_inner confirm">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form_outwrap">
                  <div class="modal_header">
                    <p class="ttl_header">確認</p>
                  </div>
                  <div class="modal_wrap">
                    <div class="for_wrap h_80">
                      <div class="modal_contents">
                        <p>
                          <span
                            >入力途中の内容は削除されますがよろしいですか？</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="modal_footer">
                    <div class="flex_wrap f_c">
                      <div class="btn_norm reverse" id="cancel_bk">
                        <button
                          name=""
                          value="キャンセル"
                          class="close"
                          dusk="delete_data"
                          @click="closeDiscard()"
                        >
                          キャンセル
                        </button>
                      </div>
                      <div class="btn_norm delete">
                        <button
                          @click="discardModal()"
                          class="btn btn-primary cln_form_btn"
                          value="アーカイブ"
                          title="削除"
                          dusk="save"
                        >
                          実行
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p
            id="close_btn"
            class="pop-remove_btn ttoltip tip-top close"
            @click="closeDiscard()"
          >
            <i class="fas fa-times"></i>
          </p>
          <!-- <div class="close_btn tooltip tip-left" title="閉じる">
				<i class="fas fa-times"></i>
			</div> -->
        </div>
        <!--modal_inner-->
      </div>

      <div class="toggle_wrap">
        <div class="toggle_dsn">
          <a @click="navigateToShow()" style="cursor: pointer">表示</a>
          <span class="modeon">編集</span>
        </div>
        <!--toggle_dsn-->
      </div>

      <Spinner v-if="spinner" />

      <Form
        enctype="multipart/form-data"
        autocomplete="off"
        @submit="onSubmit"
        :validation-schema="schema"
        v-slot="{ errors }"
      >
        <div class="command_wrap command_bottom">
          <div class="leftarea btn_wrap">
            <div class="return_link">
              <a id="backToList" dusk="to_list_screen">
                <span class="icon"><i class="fas fa-chevron-left"></i></span>
                イベント一覧
              </a>
            </div>
            <div></div>
          </div>
          <!--leftarea-->
          <div class="rightarea">
            <div>
              <div class="btn_norm">
                <button type="submit" dusk="com_save_button">保存</button>
              </div>
            </div>
            <div class="commandtext_wrap">
              <div class="commandtextlist"></div>
            </div>
            <!--commandtext_wrap-->
          </div>
          <!--rightarea-->
        </div>
        <!--command_wrap-->

        <div class="contentout_wrap">
          <div class="content_wrap twocolumn">
            <div class="box_wrap left">
              <h2 class="ttl_2">イベント編集</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr>
                        <th class="wid_200px">タイトル</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <Field
                                  name="title"
                                  type="text"
                                  class="form-control"
                                  placeholder="タイトルを入力してください"
                                  :class="{ 'is-invalid': errors.title }"
                                  v-model="loadEventData.title"
                                  id="title"
                                />
                                <ErrorMessage class="each_msg" name="title" />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_200px">本文</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0">
                              <div class="in_wrap">
                                <textarea
                                  class="form-control"
                                  name="body"
                                  style="visibility: hidden; display: none"
                                >
                                </textarea>
                                <ckeditor
                                  :editor="editor"
                                  :config="editorConfig"
                                  :disabled="editorDisabled"
                                  @ready="onEditorReady"
                                  @focus="onEditorFocus"
                                  @blur="onEditorBlur"
                                  @input="onEditorInput"
                                  @destroy="onEditorDestroy"
                                  v-model="loadEventData.content"
                                ></ckeditor>
                                <Field
                                  name="content"
                                  type="hidden"
                                  id="content"
                                  class="form-control"
                                  placeholder="本文を入力してください"
                                  :class="{ 'is-invalid': errors.content }"
                                  v-model="loadEventData.content"
                                />
                                <ErrorMessage class="each_msg" name="content" />
                              </div>
                              <!--in_wrap-->
                            </div>
                            <!--wrap-->
                          </div>
                          <!--form_outwrap-->
                        </td>
                      </tr>

                      <tr>
                        <th class="wid_200px">開催日程</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_400px">
                              <p class="ttl_3">開始日時</p>
                              <div class="in_wrap time_wrap">
                                <div class="datebox ttl_3">
                                  <input
                                    name="start-datetime"
                                    type="datetime-local"
                                    placeholder="年月日を選択"
                                    class="input_date start_datetime"
                                    id="start-datetime"
                                  />
                                  <span style="display: none">@</span>
                                  <input
                                    style="display: none"
                                    name="public_hour"
                                    type="text"
                                    placeholder="00"
                                    class="hour start_datetime"
                                  />
                                  <span style="display: none">:</span>
                                  <input
                                    style="display: none"
                                    name="public_sec"
                                    type="text"
                                    placeholder="00"
                                    class="minutes start_datetime"
                                  />
                                </div>
                                <!--datepickbox-->

                                <div
                                  class="msg_wrap"
                                  v-if="startDateNullValidation"
                                >
                                  <span class="each_msg"
                                    >開始日を入力ください。<span
                                      class="trg"
                                    ></span
                                  ></span>
                                </div>
                                <div class="msg_wrap" v-if="startDateValidated">
                                  <span class="each_msg"
                                    >開始日には終了日以前の日付をご利用ください。<span
                                      class="trg"
                                    ></span
                                  ></span>
                                </div>
                              </div>
                              <!--in_wrap-->
                            </div>
                          </div>
                          <div class="form_outwrap">
                            <div class="wrap wid_400px">
                              <p class="ttl_3">終了日時</p>
                              <div class="in_wrap time_wrap">
                                <div class="ttl_3">
                                  <input
                                    name="end-datetime"
                                    type="datetime-local"
                                    placeholder="年月日を選択"
                                    class="input_date end_datetime"
                                    id="end-datetime"
                                  />
                                  <span style="display: none">@</span>
                                  <input
                                    style="display: none"
                                    name="end_hour"
                                    type="text"
                                    placeholder="00"
                                    class="hour end_datetime"
                                  />
                                  <span style="display: none">:</span>
                                  <input
                                    style="display: none"
                                    name="end_sec"
                                    type="text"
                                    placeholder="00"
                                    class="minutes end_datetime"
                                  />
                                </div>
                                <!--datepickbox-->
                                <div class="msg_wrap" v-if="endDateValidated">
                                  <span class="each_msg"
                                    >終了日には開始日以降の日付をご利用ください。<span
                                      class="trg"
                                    ></span
                                  ></span>
                                </div>
                              </div>
                              <!--in_wrap-->
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th class="wid_200px">開催場所</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <Field
                                  name="venue"
                                  type="text"
                                  class="form-control"
                                  placeholder="三宮駅前○○ホール"
                                  v-model="loadEventData.place"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th class="wid_200px">定員（人）</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_20per p_r0 m_b0">
                              <div class="in_wrap">
                                <Field
                                  name="capacity"
                                  type="text"
                                  class="form-control"
                                  placeholder="20"
                                  v-on:keypress="NumbersOnly"
                                  v-model="loadEventData.capacity"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_200px">イベントお問い合せ先</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <div class="wid_400px"></div>
                                <Field
                                  name="contact-info"
                                  type="text"
                                  class="form-control"
                                  placeholder="012-345-6789"
                                  v-model="loadEventData.contact"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_200px">関連資料</th>
                        <td>
                          <div class="row_form_wrap">
                            <div class="row_wrap header nosort">
                              <div class="col">
                                <p class="ttl_3">資料タイトル</p>
                              </div>
                              <div class="col">
                                <p class="ttl_3">
                                  アップロードファイル（PDF形式）
                                </p>
                              </div>
                              <div class="icon col wid_50px"></div>
                            </div>
                          </div>
                          <div class="row_form_wrap m_b20" id="itembox_doc">
                            <template v-if="viewFile.length > 0">
                              <div
                                v-for="file in viewFile"
                                :key="file.id"
                                class="row_wrap sort_item"
                              >
                                <div class="col" v-if="file.extension == 'pdf'">
                                  <div class="form_outwrap">
                                    <div
                                      class="wrap wid_100per celltxtarea m_b0 p_r0"
                                    >
                                      <div class="in_wrap">
                                        <input
                                          name="file_meta_value[]"
                                          type="text"
                                          :value="file.display_name"
                                          class="f_500 updatedFile"
                                          :data-id="file.id"
                                          placeholder="新規開校○○スクールのご案内"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <!--form_outwrap-->
                                </div>
                                <div
                                  class="col item_list"
                                  v-if="file.extension == 'pdf'"
                                >
                                  <div class="form_outwrap">
                                    <div
                                      class="wrap wid_100per celltxtarea m_b0 p_r0"
                                    >
                                      <div class="in_wrap">
                                        <a
                                          :href="file.url"
                                          target="_blank"
                                          class="links"
                                          >{{ file.updaload_name }}</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <!--form_outwrap-->
                                </div>
                                <div
                                  class="icon col wid_50px t_c"
                                  v-if="file.extension == 'pdf'"
                                >
                                  <div class="remove_wrap">
                                    <span
                                      @click="deleteFile(file.id)"
                                      class="item_doc_remove_btn item_remove_btn tooltip tip-left"
                                      title="削除"
                                      dusk="remove_shiryo"
                                      ><i class="fas fa-times"></i
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <!--row_wrap-->
                              <!--リストアイテムのテンプレート-->
                            </template>
                          </div>

                          <!--row_form_wrap-->
                          <p class="commandtext t_c">
                            <span class="item_doc_add_btn" dusk="zairyo_add"
                              ><i class="material-icons">add</i
                              >関連資料追加</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <!--box_wrap-->
            <div class="box_wrap right">
              <h2 class="ttl_2">公開情報</h2>

              <div class="wrap wid_100per p_r0" v-if="isEyeCatchView">
                <div>
                  <p class="ttl_3">
                    アイキャッチ<br /><small class="input_description"
                      >推奨サイズ：縦400×横270</small
                    >
                  </p>
                </div>
                <div class="in_wrap ec_wrap">
                  <img
                    :src="'data:image/png;base64,' + isEyeCatchView"
                    alt=""
                  />
                  <p
                    id="ec_delete"
                    class="remove_btn tooltip tip-left delete-eyecatch"
                    title="削除"
                    dusk="delete_eyecatch"
                  >
                    <i class="fas fa-times"></i>
                  </p>
                  <div id="prev"></div>
                </div>
                <!--in_wrap-->
              </div>
              <div class="wrap wid_100per p_r0" v-else>
                <div><p class="ttl_3">アイキャッチ</p></div>
                <div class="in_wrap">
                  <div class="filegroup m_b20">
                    <input
                      type="file"
                      id="ec_pls"
                      name="eyecatch"
                      value=""
                      class="ec_catch eye_catch_create"
                      accept=".jpg"
                    />
                    <label for="ec_pls" class="filelabel">ファイルを選択</label>
                    <input type="hidden" name="ec_pastdel" value="del" />
                  </div>
                </div>
                <div id="prev"></div>
              </div>

              <div class="form_outwrap">
                <div class="wrap wid_100per p_r0">
                  <div><p class="ttl_3">公開ステータス</p></div>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        required=""
                        name="status"
                        as="select"
                        class="form-control"
                        v-model="loadEventData.status"
                      >
                        <option value="下書き">下書き</option>
                        <option value="公開">公開</option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="status" />
                  </div>
                </div>
                <!--wrap-->
                <div class="wrap wid_100per p_r0" style="display: none">
                  <div><p class="ttl_3">カテゴリ</p></div>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        name="type"
                        as="select"
                        class="form-control"
                        v-model="loadEventData.type"
                      >
                        <option value="event">イベント</option>
                      </Field>
                    </div>
                    <ErrorMessage class="each_msg" name="type" />
                  </div>
                </div>
                <!--wrap-->
                <div class="wrap p_r0">
                  <!--in_wrap-->
                </div>
                <!--wrap-->
                <div class="wrap wid_100per p_r0">
                  <div><p class="ttl_3">記事登録</p></div>
                  <div class="in_wrap" v-if="loadEventData.user != ''">
                    <p>
                      登録日： {{ format_date(loadEventData.created_at) }}
                      <br />
                      作成者：
                      {{
                        loadEventData.create_user
                          ? loadEventData.create_user.last_name
                          : "" + " "
                      }}
                      {{
                        loadEventData.create_user
                          ?
                          loadEventData.create_user.deleted_at ?
                          loadEventData.create_user.first_name + ' (削除済み) ': loadEventData.create_user.first_name
                          : ""
                      }}
                    </p>
                  </div>
                </div>
                <!--wrap-->
                <div class="wrap wid_100per p_r0">
                  <div><p class="ttl_3">最終更新</p></div>
                  <div class="in_wrap" v-if="loadEventData.user != ''">
                    <p>
                      更新日：{{ format_date(loadEventData.updated_at) }} <br />
                      作成者：
                      {{
                        loadEventData.update_user
                          ? loadEventData.update_user.last_name
                          : "" + " "
                      }}
                      {{
                        loadEventData.update_user
                          ?  loadEventData.update_user.delete_at ?
                           loadEventData.update_user.first_name + ' (削除済み) ' : loadEventData.update_user.first_name
                          : ""
                      }}
                    </p>
                  </div>
                </div>
                <!--wrap-->
                <!--wrap-->
              </div>
              <!--form_outwrap-->
            </div>

            <div class="box_wrap left" style="display: none">
              <h2 class="ttl_2">申込フォーム</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr>
                        <th class="wid_20per">申込フォームの利用</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per m_t5">
                              <p class="ttl_3">
                                <small class="input_description"
                                  >※利用するにチェックを入れた場合、イベントページに申込リンクが表示されます</small
                                >
                              </p>
                              <div class="in_wrap">
                                <label>
                                  <input
                                    id="chk_use"
                                    type="checkbox"
                                    name="event_reservation_form"
                                    value="1"
                                    class="chkbox"
                                    checked=""
                                  /><span></span
                                  ><span class="chktxt">利用する</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr class="event_form_usecase" style="">
                        <th class="wid_20per">入力項目の追加</th>
                        <td>
                          <div
                            id="item_add_wrap"
                            class="form_outwrap item_form"
                          >
                            <p class="ttl_3">項目名</p>
                            <div class="item_add_list">
                              <div class="col">
                                <div class="item_form">
                                  <div
                                    class="wrap wid_100per celltxtarea m_b10"
                                  >
                                    <div class="in_wrap">
                                      <p></p>
                                      <input
                                        name="event_form_adding_item[]"
                                        type="hidden"
                                        placeholder="項目名を入力してください"
                                        class=""
                                        value="first"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="icon col wid_50px t_c">
                                <div class="remove_wrap">
                                  <!-- <span style="vertical-align: top;" class="item_add_remove_btn item_remove_btn remove_btn tooltip tip-left" title="削除"><i class="material-icons">clear</i></span> -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form_outwrap item_form"></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>

            <!--box_wrap-->
          </div>
          <!--content_wrap-->
        </div>
        <!--contentoutwrap-->
      </Form>
    </div>
    <!--maincontentinner-->
    <template id="item_doc_Template">
      <div class="row_wrap new_item sort_item">
        <div class="col">
          <div class="form_outwrap">
            <div class="wrap wid_100per celltxtarea m_b0 p_r0">
              <div class="in_wrap">
                <input type="hidden" name="jdg_val[]" value="new" />
                <input
                  name="rel_doc_title[]"
                  type="text"
                  class="fileName uploadFileName"
                  required
                  placeholder="例：1月の営業スケジュールについて"
                />
              </div>
              <div class="fileDisplayName each_msg"></div>
            </div>
          </div>
          <!--form_outwrap-->
        </div>
        <div class="col item_list">
          <div class="form_outwrap">
            <div class="wrap wid_100per celltxtarea m_b0 p_r0">
              <div class="in_wrap">
                <div class="filegroup">
                  <input
                    type="file"
                    name="rel_doc[]"
                    class="input-file"
                    style="display: none"
                    accept=".pdf"
                    required
                  />
                  <label class="filelabel fileup-btn">ファイルを選択</label>
                  <span class="filename chooseFileNameError"
                    >選択してください</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--form_outwrap-->
        </div>
        <div class="icon col wid_50px t_c">
          <div class="remove_wrap">
            <a
              href=""
              class="item_doc_remove_btn item_remove_btn remove_btn tooltip tip-left"
              title="削除"
              ><i class="fas fa-times"></i
            ></a>
          </div>
        </div>
      </div>
    </template>

    <div
      class="modal"
      id="discardModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal_inner confirm">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form_outwrap">
                <div class="modal_header">
                  <p class="ttl_header">破棄</p>
                </div>
                <div class="modal_wrap">
                  <div class="for_wrap h_80">
                    <div class="modal_contents">
                      <p>
                        <span
                          >入力途中の内容は削除されますがよろしいですか？</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <div class="modal_footer">
                  <div class="flex_wrap f_c">
                    <div class="btn_norm reverse" id="cancel_bk">
                      <button
                        name=""
                        value="キャンセル"
                        class="close"
                        dusk="delete_data"
                      >
                        キャンセル
                      </button>
                    </div>
                    <div class="btn_norm delete">
                      <button
                        @click="discardModal()"
                        class="btn btn-primary cln_form_btn"
                        value="アーカイブ"
                        title="削除"
                        dusk="save"
                      >
                        実行
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p id="close_btn" class="pop-remove_btn ttoltip tip-top close">
          <i class="fas fa-times"></i>
        </p>
        <!-- <div class="close_btn tooltip tip-left" title="閉じる">
				<i class="fas fa-times"></i>
			</div> -->
      </div>
      <!--modal_inner-->
    </div>
    <!--modal-->
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import EventUtility from "@/apis/admin_Event";
import ClassicEditor from "ckeditor5-build-classic-with-base64-and-autolink/build/ckeditor";
import moment from "moment";

import Spinner from "../../../components/control_panel/Spinner.vue";
import $ from "jquery";
import "@ckeditor/ckeditor5-build-classic/build/translations/ja";

import { mapState, mapGetters, mapActions } from "vuex";

import Vue from "vue";

export default {
  name: "Event Edit",
  setup() {
    /**
     * setup for validation
     * @author Elavarasan
     *  @date 23/10/2021
     */
    let schema = Yup.object().shape({
      title: Yup.string().required("タイトルを入力してください"),
      status: Yup.string().required("ステータスを選択してください"),
      content: Yup.string().required("本文を入力してください"),
    });
    return {
      schema,
    };
  },
  data() {
    return {
      eventEdit: {},
      editor: ClassicEditor,
      editorConfig: {
        language: "ja",
      },
      files: {},
      fileData: [],
      fileUpdateData: [],
      fileDeleteData: [],
      date: "",
      hours: "00",
      minutes: "00",
      hoursValidated: true,
      minutesValidated: true,
      dateValidated: true,
      gotoList: true,
      commonValidationError: false,
      fileUploadError: true,
      fileDisplayError: true,
      editShowEvent: this.showEvent,
      iniLoadFlag: null,
      deleteEventsId: [],
      updateEventsFiles: [],
      profilePicture: "",
      isEyeCatchView: "",
      isEyeCatchDelete: false,
      userImageUpload: "",
      eyeCatch: "",

      startDateNullValidation: false,
      endDateNullValidation: false,

      startDateValidated: false,
      endDateValidated: false,
      startDateTimeValue: "",
      endDateTimeValue: "",
      loadEventData: {},
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Spinner,
  },
  created() {
    this.viewEvent(this.$route.params.id);
    this.getFileEvent(this.$route.params.id);
    this.getEventEyeCatch(this.$route.params.id);
  },

  computed: {
    ...mapState("event", ["showEvent"]),

    ...mapState("file", ["viewFile", "picture"]),

    ...mapState("user", ["userFullName"]),

    ...mapGetters("user", ["fullName"]),

    ...mapState("common", ["spinner"]),
  },
  watch: {
    showEvent(val) {
      this.loadEventData = val;

      if (this.iniLoadFlag == null) {
        $(".input_date.start_datetime").val(
          moment(
            this.loadEventData.implementation_datetime.start_datetime
          ).format("YYYY-MM-DDTHH:mm")
        );
        $(".input_date.end_datetime").val(
          moment(
            this.loadEventData.implementation_datetime.end_datetime
          ).format("YYYY-MM-DDTHH:mm")
        );
        this.startDateTimeValue = val.implementation_datetime.start_datetime;
        this.endDateTimeValue = val.implementation_datetime.end_datetime;
      }

      this.iniLoadFlag = 1;
    },
    picture(img) {
      for (let index = 0; index < img.length; index++) {
        const element = img[index];
        if (element.key === "eyecatch") {
          this.profilePicture = element.base64;
          this.isEyeCatchView = element.base64;
          this.eyeCatch = element;
        }
      }
    },
  },
  methods: {
    /**
     * get event
     * @author Manikandan
     *  @date 16/11/2021
     */
    ...mapActions("event", ["viewEvent", "updateEvent"]),

    ...mapActions("file", [
      "delteFileEvent",
      "getFileEvent",
      "updateFileEvent",
      "getEventEyeCatch",
    ]),

    ...mapActions("common", ["setSpinnerStatus", "setSucccessMessageStatus"]),

    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    /**
     * submit the notification edit data
     * @author Elavarasan
     *  @date 23/10/2021
     */
    onSubmit() {
      if (
        this.startDateNullValidation !== true &&
        this.startDateValidated !== true &&
        this.endDateValidated !== true
      ) {
        if (this.fileUploadError && this.fileDisplayError) {
          this.loadEventData.implementation_datetime.start_datetime =
            this.startDateTimeValue;

          this.loadEventData.reception_period.start_datetime =
            this.startDateTimeValue;

          if (this.endDateTimeValue !== "" && this.endDateTimeValue != null) {
            this.loadEventData.implementation_datetime.end_datetime =
              this.endDateTimeValue;

            this.loadEventData.reception_period.end_datetime =
              this.endDateTimeValue;
          } else {
            this.loadEventData.implementation_datetime.end_datetime =
              moment(this.startDateTimeValue).format("YYYY-MM-DD") + " 23:59";

            this.loadEventData.reception_period.end_datetime =
              moment(this.startDateTimeValue).format("YYYY-MM-DD") + " 23:59";
          }
          this.commonValidationError = false;

          this.setSpinnerStatus(true);

          let isUpdate = false;

          if (this.profilePicture != "") {
            isUpdate = true;
          } else {
            isUpdate = false;
          }
          $("#js-loader").fadeIn();
          this.updateEvent({
            event: this.loadEventData,
            id: this.$route.params.id,
            files: this.fileData,
            deleteEventsId: this.deleteEventsId,
            updateEventsFiles: this.updateEventsFiles,
            eyeCatch: this.userImageUpload,
            isUpdate: isUpdate,
            eyeCatchId: this.eyeCatch ? this.eyeCatch.id : "",
            isEyeCatchDelete: this.isEyeCatchDelete,
          });
          $("#js-loader").fadeOut();
          this.setSucccessMessageStatus({
            status: true,
            message: "イベントが正常に更新されました。",
          });
        }
      }
    },

    /**
     * date format customise
     * @author Elavarasan
     *  @date 23/10/2021
     */
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY/MM/DD HH:mm");
      }
    },
    format_date_only(value) {
      if (value) {
        return moment(String(value)).format("YYYY/MM/DD");
      }
    },
    format_hours_only(value) {
      if (value) {
        return moment(String(value)).format("HH");
      }
    },

    format_minutes_only(value) {
      if (value) {
        return moment(String(value)).format("mm");
      }
    },
    /**
     * On editor input for CKEditor
     * @author Manikandan
     *  @date 16/11/2021
     */
    onEditorInput(data, event, editor) {
      editor.editing.view.document.on(
        "enter",
        (evt, data) => {
          if (data.isSoft) {
            editor.execute("enter");
          } else {
            editor.execute("shiftEnter");
          }

          data.preventDefault();
          evt.stop();
          editor.editing.view.scrollToTheSelection();
        },
        { priority: "high" }
      );
    },
    /**
     * discurd the changes
     * @author Manikandan
     *  @date 16/11/2021
     */
    discardModal() {
      if (this.gotoList) {
        this.$router.push({ path: "/admin/event" });
      } else {
        this.$router.push({
          name: "Event Show",
          params: { id: this.$route.params.id },
        });
      }
    },
    /**
     * close discurd confirm popup
     * @author Elavarasan
     *  @date 23/10/2021
     */
    closeDiscard() {
      $("#discardModal").removeClass("open");
    },
    /**
     * Navigate bredcubms routes
     * @author Elavarasan
     *  @date 23/10/2021
     */
    navigate(url) {
      if (this.formInputEvent == true) {
        $("#discardModal").addClass("open");
      } else {
        this.$router.push({ path: url });
      }
    },
    navigateToShow() {
      this.gotoList = false;
      if (this.formInputEvent == true) {
        $("#discardModal").addClass("open");
      } else {
        this.$router.push({
          name: "Event Show",
          params: { id: this.$route.params.id },
        });
      }
    },

    /**
     * delete file route
     * @author Elavarasan
     *  @date 23/10/2021
     */
    deleteFile(deletedFileId) {
      this.deleteEventsId.push(deletedFileId);
    },

    /**
     * get file storage name
     * @author Elavarasan
     *  @date 23/10/2021
     */
    fileStorageName(file) {
      EventUtility.fileStorageName(file)
        .then(
          (response) => {
            console.log(response);
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {})
        .finally(() => {});
    },
    validateDate(dateString) {
      return moment(dateString, "YYYY-MM-DDTHH:mm", true).isValid();
    },
  },
  mounted() {
    let vueThis = this;
    let inputDirty = false;

    /**
     * update file details get
     * @author Elavarasan
     *  @date 23/10/2021
     */
    $(document).on("blur", ".updatedFile", function (e) {
      var $el = $(e.currentTarget);

      var updateFileName = vueThis.viewFile;

      for (const iterator of updateFileName) {
        if (iterator.id == $el.attr("data-id")) {
          iterator.display_name = $el.val();
        }
      }

      vueThis.updateEventsFiles = updateFileName;
    });

    $(document).on("click", ".delete-eyecatch", function (e) {
      $(this)
        .next()
        .after(
          '<div class="filegroup m_b20"><input id="ec_pls" type="file" name="eyecatch" value="" accept=".jpg" class="ec_catch"><label for="ec_pls" class="filelabel">ファイルを選択</label><input type="hidden" name="ec_pastdel" value="del"></div>'
        );
      $("#ec_img").hide();
      $(this).hide();
      vueThis.isEyeCatchView = "";
      vueThis.isEyeCatchDelete = true;
    });

    /**
     * form input changes find
     * @author Elavarasan
     *  @date 23/10/2021
     */
    $("form").change(function () {
      inputDirty = true;
      vueThis.formInputEvent = true;
    });

    /**
     * back to list naviation route
     * @author Elavarasan
     *  @date 23/10/2021
     */
    $("#backToList").click(function () {
      if (inputDirty == true) {
        $("#discardModal").addClass("open");
      } else {
        vueThis.$router.push({ path: "/admin/event" });
      }
    });

    /**
     * File change validation
     * @author Elavarasan
     *  @date 23/10/2021
     */
    $(document).on("change", ".input-file", function (e) {
      var file = event.target.files[0];

      // if (file.size > 2000000) {
      //   $(this).siblings(".filename").text("2 mb以下のファイルを指定してください。");
      //   $(this).siblings(".filename").addClass("each_msg");
      //   return false;
      // }

      $(this).siblings(".filename").text("選択されていません");

      var permit_type = ["application/pdf"];
      if (file && permit_type.indexOf(file.type) == -1) {
        alert("アップロードできるファイルの形式はpdfです");
        $(this).val("");
        return;
      }

      $(this).siblings(".filename").text($(this).prop("files")[0].name);
      $(this).siblings(".filename").removeClass("each_msg");
    });
    /**
     * file view delete option
     * @author Elavarasan
     *  @date 23/10/2021
     */
    $(document).on("click", ".item_doc_remove_btn", function (e) {
      var $el = $(e.currentTarget);
      var $row = $el.closest(".row_wrap,.new_item");
      $row.remove();

      var $fileDisplayName = $el
        .closest(".row_wrap,.new_item")
        .find(".fileName");

      for (var i = 0; i < vueThis.fileData.length; i++) {
        if (vueThis.fileData[i].display_name == $fileDisplayName.val()) {
          vueThis.fileData.splice(i, 1);
          break;
        }
      }
      return false;
    });

    $(function () {
      $(document).on("blur", ".input_date.start_datetime", function () {
        vueThis.startDateTimeValue = $(this).val();
      });
      $(document).on("blur", ".input_date.end_datetime", function () {
        vueThis.endDateTimeValue = $(this).val();
      });

      $(document).on("blur", ".input_date.start_datetime", function () {
        let startDate = Date.parse($(this).val());
        if ($(this).val() !== "") {
          vueThis.startDateNullValidation = false;
          let endDate = vueThis.endDateTimeValue;
          if (endDate != null && endDate != "") {
            if (startDate <= Date.parse(endDate)) {
              vueThis.startDateValidated = false;
              vueThis.endDateValidated = false;
            } else {
              vueThis.startDateValidated = true;
            }
          } else {
            vueThis.startDateValidated = false;
          }
        } else {
          vueThis.startDateNullValidation = true;
        }
      });

      $(document).on("change", ".input_date.end_datetime", function () {
        let endDate = Date.parse($(this).val());

        let startDate = vueThis.startDateTimeValue;

        if ($(this).val() !== "") {
          if (startDate != null && startDate != "") {
            if (endDate >= Date.parse(startDate)) {
              vueThis.endDateValidated = false;
              vueThis.startDateValidated = false;
            } else {
              vueThis.endDateValidated = true;
            }
          } else {
            vueThis.endDateValidated = false;
          }
        } else {
          vueThis.endDateValidated = false;
        }
      });
    });

    $(document).ready(function () {
      var hsize = $(window).height();
      $(".maincontent").css("min-height", hsize - 60 + "px");
      $(".tooltip").tooltip({
        show: false,
        hide: false,
        tooltipClass: "tipinfo",
      });
      $(".tip-top").tooltip({
        position: {
          my: "center top",
          at: "center top",
        },
      });
      $(".tip-bottom").tooltip({
        position: {
          my: "center bottom",
          at: "center top",
        },
      });
      $(".tip-left").tooltip({
        position: {
          my: "left top",
          at: "right top",
        },
      });
      $(".tip-right").tooltip({
        position: {
          my: "right top",
          at: "left top",
        },
      });
    });

    $(document).on("click", "button[type=submit]", function () {
      if ($("#title").val() == "" || $("#content").val() == "") {
        vueThis.commonValidationError = true;
      } else {
        vueThis.commonValidationError = false;
      }
    });

    $(document).on("click", "button[type=submit]", function (e) {
      var $el = $(e.currentTarget);
      var $file = $el.closest("form").find(".row_wrap.new_item");

      vueThis.fileData = [];

      $file.each(function () {
        if ($(this).find(".uploadFileName").val() == "") {
          $(this).find(".fileDisplayName").html("ファイル名を入力してください");
          vueThis.fileDisplayError = false;
        } else {
          $(this).find(".fileDisplayName").html("");
          vueThis.fileDisplayError = true;
        }

        if ($(this).find(".input-file").val() == "") {
          $(this).find(".chooseFileNameError").addClass("each_msg");
          $(this)
            .find(".chooseFileNameError")
            .html("ファイルを入力してください");
          vueThis.fileUploadError = false;
        } else {
          $(this).find(".chooseFileNameError").removeClass("each_msg");
          $(this).find(".chooseFileNameError").html();
          vueThis.fileUploadError = true;
        }

        if (
          $(this).find(".uploadFileName").val() != "" &&
          $(this).find(".input-file").val() != ""
        ) {
          let fileObject = new FormData();

          fileObject.append("key", "event_attached_file");

          fileObject.append(
            "display_name",
            $(this).find(".uploadFileName").val()
          );

          fileObject.append(
            "uploadfile",
            $(this).find(".input-file").prop("files")[0]
          );

          vueThis.fileData.push(fileObject);
        }
      });
    });

    $(document).on("change", ".ec_catch", function (e) {
      let fileObject = new FormData();

      var file = $(this).prop("files")[0];

      fileObject.append("key", "eyecatch");
      fileObject.append(
        "display_name",
        $(this)
          .val()
          .replace(/C:\\fakepath\\/i, "")
      );
      fileObject.append("uploadfile", file);
      vueThis.userImageUpload = fileObject;
    });
  },
};
</script>
<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
@import "~@/assets/front_component/js/timepicker/datepicker.css";
</style>
